import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import PrimChart from "../../images/ida/efficacy/Primary_Endpoint_Chart-LG@2x.png"
import PrimChartSM from "../../images/ida/efficacy/Primary_Endpoint_Chart-SM@2x.png"
import EfficacyChart from "../../images/ida/efficacy/Efficacy-Hgb_increase-chart-LG@2x.png"
import EfficacyChartSM from "../../images/ida/efficacy/Efficacy-Hgb_increase-chart-sm@2x.png"
import TsatChart from "../../images/ida/efficacy/Efficacy-TSAT-Changes-chart-SM@2x.png"
import TsatChart2 from "../../images/ida/efficacy/Efficacy-TSAT-Changes-chart2-SM@2x.png"
import TranscriptAccordion from "../../components/shared/transcript-accordion"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import akbcares from "../../images/AkebiaCares-logo.png"
import PrescribeIcon from "../../images/icons/icon-callout-ida-dosing.png"

const anchorClick = event => {
    event.preventDefault()
    event.stopPropagation()

    const bounds = document
        .getElementById("trial-design")
        .getBoundingClientRect()
    let scrollPosition = window.pageYOffset + bounds.top

    // Adjust for the height of the sticky header
    let stickyHdr
    setTimeout(function () {
        if (window.matchMedia("(min-width: 768px)").matches) {
            stickyHdr = document
                .getElementById("site-navigation")
                .getBoundingClientRect().height
        } else {
            stickyHdr = document
                .getElementById("ida-navigation")
                .getBoundingClientRect().height
        }
        scrollPosition = scrollPosition - stickyHdr
        window.scrollTo({ top: scrollPosition, behavior: "smooth" })
    }, 100)
}

const IdaEfficacy = ({ location, data }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
            <>
                Fishbane S, Block GA, Loram L, et al. Effects of ferric citrate
                in patients with nondialysis-dependent CKD and iron deficiency
                anemia. <em>J Am Soc Nephrol.</em> 2017;28(6):1851-1885.
            </>,
            <>Data on File 16, Akebia Therapeutics, Inc.</>,
            <>Data on File 14, Akebia Therapeutics, Inc.</>,
            <>Data on File 17, Akebia Therapeutics, Inc.</>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/efficacy/"
            />
            <body id="ida-hcp-efficacy" className="ida" />
        </Helmet>

        <Seo
            title="Efficacy"
            description="See the efficacy results for AURYXIA in a 16-week trial. See Prescribing Information and Full Safety Information."
        />

        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>
                        The first and only oral iron specifically indicated for
                        adults with&nbsp;CKD-NDD
                    </h1>

                    <p>
                        Discover the Hb and iron parameter results for AURYXIA
                        from the 16-week pivotal trial.<sup>1-4</sup>
                    </p>

                    <p className="footnote center">
                        CKD=chronic kidney disease; NDD=non-dialysis dependent.
                    </p>
                </div>
            </div>
        </section>

        <section id="endpoint" className="flood-ida col-8 center">
            <div>
                <div>
                    <h2 className="ida ida_maxWidth">
                        AURYXIA increased Hb &#x2265;1.0 g/dL in the majority of
                        treated patients<sup>1</sup>
                    </h2>

                    <div
                        className="charts flex-row"
                        data-sal="slide-up"
                        data-sal-duration="600"
                        data-sal-delay="100"
                        data-sal-easing="ease-out-expo"
                    >
                        <div>
                            <h3 className="ida">Primary endpoint</h3>

                            <p>
                                Proportion of patients achieving an Hb increase
                                of &#x2265;1.0 g/dL at any time by Week&nbsp;16
                            </p>

                            <img
                                src={PrimChart}
                                className="large-only"
                                alt="Primary endpoint"
                            />
                            <img
                                src={PrimChartSM}
                                className="small-only"
                                alt="Primary endpoint"
                            />
                            <p>
                                All efficacy endpoints were achieved without ESA
                                or IV iron use, per exclusion criteria and study
                                design.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="transcript">
            <TranscriptAccordion
                siteAudience="ida"
                transcriptTitle="VIEW DATA TABLE"
                transcriptContent={
                    <>
                        <div className="table-wrap">
                            <h4 className="ida">
                                Summary of change in Hb (g/dL) from baseline
                                over time during
                                <br /> randomized period based on observed
                                values<sup>3</sup>
                            </h4>
                            <table>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <th>
                                            <strong>FERRIC CITRATE</strong>
                                        </th>
                                        <th>
                                            <strong>PLACEBO</strong>
                                        </th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <th>
                                            <strong>Mean Hb</strong>
                                            <br />
                                            (g/dL)
                                        </th>
                                        <th>
                                            <strong>Mean Hb</strong>
                                            <br />
                                            (g/dL)
                                        </th>
                                        <th className="light">
                                            <strong>
                                                Change from Baseline
                                            </strong>
                                            <br />(<em>P</em>-Value)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Baseline</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.44</strong>
                                            <br />
                                            (117)
                                        </td>
                                        <td>
                                            <strong>10.38</strong>
                                            <br />
                                            (115)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 1</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.45</strong>
                                            <br />
                                            (110)
                                        </td>
                                        <td>
                                            <strong>10.25</strong>
                                            <br />
                                            (107)
                                        </td>
                                        <td>0.11 (0.07)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 2</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.55</strong>
                                            <br />
                                            (114)
                                        </td>
                                        <td>
                                            <strong>10.28</strong>
                                            <br />
                                            (103)
                                        </td>
                                        <td>0.24 (0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 4</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.68</strong>
                                            <br />
                                            (108)
                                        </td>
                                        <td>
                                            <strong>10.33</strong>
                                            <br />
                                            (100)
                                        </td>
                                        <td>0.33 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 6</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.81</strong>
                                            <br />
                                            (102)
                                        </td>
                                        <td>
                                            <strong>10.29</strong>
                                            <br />
                                            (96)
                                        </td>
                                        <td>0.49 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 8</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>10.98</strong>
                                            <br />
                                            (97)
                                        </td>
                                        <td>
                                            <strong>10.39</strong>
                                            <br />
                                            (91)
                                        </td>
                                        <td>0.57 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 10</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>11.06</strong>
                                            <br />
                                            (97)
                                        </td>
                                        <td>
                                            <strong>10.37</strong>
                                            <br />
                                            (88)
                                        </td>
                                        <td>0.69 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 12</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>11.16</strong>
                                            <br />
                                            (97)
                                        </td>
                                        <td>
                                            <strong>10.44</strong>
                                            <br />
                                            (85)
                                        </td>
                                        <td>0.69 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 14</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>11.25</strong>
                                            <br />
                                            (97)
                                        </td>
                                        <td>
                                            <strong>10.42</strong>
                                            <br />
                                            (85)
                                        </td>
                                        <td>0.81 (&lt;0.001)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Week 16</strong>
                                            <br />
                                            (n)
                                        </td>
                                        <td>
                                            <strong>11.41</strong>
                                            <br />
                                            (97)
                                        </td>
                                        <td>
                                            <strong>10.51</strong>
                                            <br />
                                            (77)
                                        </td>
                                        <td>0.84 (&lt;0.001)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            />
        </section>

        <section id="efficacy" className="col-8 center">
            <div className="flex-row">
                <div>
                    <h2 className="ida">
                        AURYXIA increased mean hemoglobin levels over 16 weeks
                        of treatment<sup>2,3</sup>
                    </h2>
                    <p>
                        <strong>Proven efficacy in CKD-NDD:</strong> 52% of
                        patients treated with AURYXIA achieved an Hb increase of
                        ≥1.0 g/dL at any time point by Week 16 (<em>P</em>
                        &lt;0.001)<sup>1</sup>
                    </p>

                    <div
                        className="charts flex-row"
                        data-sal="slide-up"
                        data-sal-duration="600"
                        data-sal-delay="100"
                        data-sal-easing="ease-out-expo"
                    >
                        <div>
                            <h3 className="ida">
                                Key secondary endpoint: Mean Hb increase from
                                10.4 g/dL to 11.4 g/dL by the end of the 16-week
                                period<sup>2,3</sup>
                            </h3>

                            <figure>
                                <figcaption>
                                    <p className="circle auryxia">
                                        AURYXIA (n=117)
                                    </p>
                                    <p className="circle other">
                                        PLACEBO (n=115)
                                    </p>
                                </figcaption>
                                <img
                                    src={EfficacyChart}
                                    className="large-only"
                                    alt="Key secondary endpoint"
                                />
                                <img
                                    src={EfficacyChartSM}
                                    className="small-only"
                                    alt="Key secondary endpoint"
                                />
                            </figure>
                        </div>
                    </div>
                    <ul>
                        <li>
                            No IV iron or ESA use, as per trial design and
                            exclusion criteria<sup>1</sup>
                        </li>
                        <li>
                            Studied in patients who were unsuccessful with or
                            intolerant of traditional oral iron therapy
                            <sup>1</sup>
                        </li>
                    </ul>

                    <div className="flex-row links">
                        <div>
                            <Link
                                to="#trial-design"
                                className="gradient-link anchor-link ida"
                                onClick={anchorClick}
                            >
                                SEE TRIAL DESIGN{" "}
                                <ExpandMoreIcon viewBox="6 6 12 12" />
                            </Link>
                        </div>
                        <div>
                            <Link
                                to="/iron-deficiency-anemia/safety/adverse-reactions/"
                                className="gradient-link anchor-link ida"
                            >
                                EXPLORE SAFETY PROFILE
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="tsat" className="flood-ida col-12 center">
            <div className="flex-row">
                <div>
                    <h2 className="ida tsat_txt">
                        AURYXIA increased TSAT and ferritin over 16 weeks of
                        treatment<sup>2</sup>
                    </h2>

                    <div className="two-charts flex-row">
                        <div
                            data-sal="slide-right"
                            data-sal-duration="600"
                            data-sal-delay="100"
                            data-sal-easing="ease-out-expo"
                        >
                            <h3 className="ida">
                                <strong>Key secondary endpoint:</strong> Mean
                                change in TSAT over time<sup>4</sup>
                            </h3>

                            <figure>
                                <figcaption>
                                    <p className="circle auryxia">
                                        AURYXIA (n=117)
                                    </p>
                                    <p className="circle other">
                                        PLACEBO (n=115)
                                    </p>
                                </figcaption>
                                <img
                                    src={TsatChart}
                                    alt="Key secondary endpoint"
                                />
                            </figure>

                            <p>
                                Over 16 weeks, AURYXIA increased mean{" "}
                                <strong>TSAT from 20.2% to 35.6%</strong>
                                <sup>4</sup>
                            </p>
                        </div>
                        <p className="footnote small-only">
                            (<em>P</em>&lt;0.001)
                        </p>
                        <div
                            data-sal="slide-left"
                            data-sal-duration="600"
                            data-sal-delay="200"
                            data-sal-easing="ease-out-expo"
                        >
                            <h3 className="ida">
                                <strong>Key secondary endpoint:</strong> Mean
                                change in ferritin over time
                            </h3>
                            <figure>
                                <figcaption>
                                    <p className="circle auryxia">
                                        AURYXIA (n=117)
                                    </p>
                                    <p className="circle other">
                                        PLACEBO (n=115)
                                    </p>
                                </figcaption>
                                <img
                                    src={TsatChart2}
                                    alt="Key secondary endpoint"
                                />
                            </figure>

                            <p>
                                Over 16 weeks, AURYXIA increased{" "}
                                <strong>
                                    mean ferritin from 85.9 ng/mL to 233.5 ng/mL
                                </strong>
                                <sup>5</sup>
                            </p>
                        </div>
                        <p className="footnote small-only">
                            (<em>P</em>&lt;0.001)
                        </p>
                    </div>

                    <div className="footnotes flex-row">
                        <div>
                            <p className="footnote">
                                (<em>P</em>&lt;0.001)
                            </p>
                        </div>
                        <div>
                            <p className="footnote">
                                (<em>P</em>&lt;0.001)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="tsat-notes" className="col-10 center">
            <div className="flex-row">
                <div>
                    <ul>
                        <li>
                            Patients on AURYXIA also achieved a mean increase in
                            serum ferritin of 163 ng/mL from baseline (85.9
                            ng/mL) at Week 16<sup>1,2</sup>
                        </li>
                        <li>
                            No use of IV iron or ESAs, as per trial design and
                            exclusion criteria<sup>1</sup>
                        </li>
                        <li>
                            Studied in patients who were unsuccessful with or
                            intolerant of traditional oral iron therapy
                            <sup>1</sup>
                        </li>
                    </ul>
                    <div className="flex-row links">
                        <div>
                            <Link
                                to="#trial-design"
                                className="gradient-link anchor-link ida"
                                onClick={anchorClick}
                            >
                                SEE TRIAL DESIGN{" "}
                                <ExpandMoreIcon viewBox="6 6 12 12" />
                            </Link>
                        </div>
                        <div>
                            <Link
                                to="/iron-deficiency-anemia/safety/adverse-reactions/"
                                className="gradient-link anchor-link ida"
                            >
                                EXPLORE SAFETY PROFILE
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="ida"
            callOneClass={"highlight"}
            callOneLink={"/iron-deficiency-anemia/dosing/"}
            callOneContent={
                <>
                    <img src={PrescribeIcon} alt="" width="98" height="98" />
                    <h3>Ready to prescribe?</h3>
                    <p>
                        AURYXIA provides the convenience of at-home treatment,
                        so it&#x2019;s important to learn about dosing and
                        administration.<sup>1</sup>
                    </p>
                    <button className="cta ida">
                        <span>GET DOSING INFORMATION</span>
                    </button>
                </>
            }
            callTwoClass={"akebia-cares"}
            callTwoLink={"https://www.akebiacareshcp.com/"}
            callTwoContent={
                <>
                    <img src={akbcares} alt="AkebiaCares" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p>
                        AkebiaCares supports your patients with information and
                        resources that can help people start and stay on
                        AURYXIA.
                    </p>
                    <button className="cta akb">
                        <span>FIND SUPPORT OPTIONS</span>
                    </button>
                </>
            }
        />

        <section id="trial-design" className="flood-ida col-8">
            <div className="flex-row">
                <div>
                    <p>
                        <strong>
                            Trial design<sup>1</sup>
                        </strong>
                    </p>

                    <p>
                        In a 24-week study consisting of a 16-week, randomized,
                        double-blind, placebo-controlled efficacy period
                        followed by an 8-week, open-label safety extension
                        period, this trial evaluated the efficacy and safety of
                        AURYXIA for the treatment of iron deficiency anemia in
                        adult patients with CKD not on dialysis. Patients who
                        were intolerant of or have had an inadequate therapeutic
                        response to oral iron supplements, with hemoglobin
                        &#x2265;9.0 g/dL and &#x2264;11.5 g/dL, serum ferritin
                        &#x2264;200 ng/mL, and TSAT &#x2264;25% were enrolled.
                        Patients were randomized to treatment with either
                        AURYXIA (n=117) or placebo (n=117).
                    </p>

                    <p>
                        The primary endpoint was the proportion of patients
                        achieving a &#x2265;1.0 g/dL increase in hemoglobin at
                        any time point during the 16-week efficacy period. Use
                        of oral iron, IV iron, or ESAs was not permitted at any
                        time during the trial.
                    </p>

                    <p>Key secondary endpoints included:</p>
                    <ul>
                        <li>
                            Mean changes in hemoglobin, TSAT, ferritin, and
                            phosphorus from baseline to Week 16
                        </li>
                        <li>
                            Proportion of patients experiencing a sustained
                            treatment effect on hemoglobin (&#x2265;0.75 g/dL
                            mean change from baseline over any 4-week period
                            provided that an increase of at least 1.0 g/dL had
                            occurred during that 4-week period)
                        </li>
                    </ul>

                    <p className="footnote">
                        CKD=chronic kidney disease; Hb=hemoglobin;
                        IV=intravenous; ESAs=erythropoiesis-stimulating agents;
                        TSAT=transferrin saturation.
                    </p>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default IdaEfficacy
